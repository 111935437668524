<template>
  <div class="maintainBox">
      <div v-show="!show">
        <van-search class="beforeSeach" v-model="value" placeholder="请输入手机号查询维修订单"/>
        <van-button type="info" @click="goSearch">查询</van-button>
      </div>
      <div v-show="show">
        <van-cell-group>
          <van-cell title="手机号"/>
          <form class="NowSearch" action="/" style="width: 75%;position: absolute;top:6px;right: 0px">
            <van-search style="padding: 0;"
                v-model="value"
                show-action
                placeholder="请输入搜索关键词"
                @input="onSearch"
                @clear="clearSearch"
            />
          </form>
        </van-cell-group>
        <el-table
            :data="tableData"
            style="width: 100%;margin-top: 0.05rem;text-align: center;font-size: 0.36rem;">
          <el-table-column type="expand" align="center">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand" v-for="items in props.row.apparatus" :key="items.engineModel">
                <el-form-item label="机型">
                  <span>{{ items.engineModel}}</span>
                </el-form-item>
                <el-form-item label="机号">
                  <span>{{items.engineNumber }}</span>
                </el-form-item>
                <el-form-item label="数量">
                  <span>{{items.quantity }}</span>
                </el-form-item>
                <el-form-item label="是否修好">
                  <span>{{ items.ifRepairWell==1? '已修好':'未修好' }}</span>
                </el-form-item>
                <el-form-item label="是否取走">
                  <span>{{ items.ifTakes==1? '已取走':'未取走' }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
              label="维修单号"
              align="center"
              prop="maintainOdd">
          </el-table-column>
          <el-table-column
              label="创建时间"
              prop="createTime">
          </el-table-column>
          <el-table-column
              label="快递公司"
              align="center"
              prop="expressageCompany">
          </el-table-column>
          <el-table-column
              label="快递单号"
              align="center"
              prop="expressageOdd">
          </el-table-column>
        </el-table>
      </div>
  </div>
</template>

<script>
import leaseApi from "../../utils/leaseApi";
import { Toast } from 'vant';
export default {

  name: "maintain",
  data(){
    return{
      show:false,
      value: '',
      tableData: [],
    }
  },
  methods: {
    //点击查询按钮
    async goSearch(){
      if(!(/^1[3456789]\d{9}$/.test(this.value))){
        Toast("手机号码有误，请重新输入");
        return false;
      }
      this.show = true;
      await this.onSearch(this.value);
    },
    //input事件框
    async onSearch(val) {
      // console.log(val.length);请输入需要查询维修订单的手机号码
      if(val.length != 11 ){
        if(!(/^1[3456789]\d{9}$/.test(val))){
          if(val.length == 0){
            Toast("请输入需要查询维修订单的手机号码！");
            return false;
          }
          Toast("手机号码有误，请重新输入");
          return false;
        }
      }
      const result = await leaseApi.selectUserFix({"userPhone":val});
      if (result.code==200){
        this.tableData = result.data
        console.log(this.tableData);
        this.tableData.forEach((item)=>{
          item.createTime = item.createTime.substring(0,10);
        })
      }else {
        Toast(result.msg);
      }
    },
    //取消按钮
    clearSearch() {
      // this.show = false;
      // console.log(122325)
    },
  },
}
</script>

<style lang="less">
.maintainBox{
  padding: 0px 24px;
  padding-top: 48px;
  .beforeSeach {
    border: 1px solid #3366FF;
    border-radius: 0.1rem;
    .van-search__content{
      background: #fff;
      .van-cell{
        background: #fff;
      }
    }

  }
  .van-button--info{
    width: 100%;
    border-radius: 0.1rem;
    margin-top: 90px;
    background-color: #3366FF;
  }
  ///deep/.maintainBox{
  //  width: 85% !important;
  //}
}
#app{
  background: #fff;
}

.demo-table-expand {
  font-size: 0;
  padding: 0.025rem 0rem;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
[class*=van-hairline]::after{
  border: 0px;
}
.van-cell::after{
  border-bottom: 0px;
}
.el-table__row{
  height: 1.46rem!important;
}
.el-table .cell{
  line-height: 0.50667rem;
}
.el-table__expand-icon{
  height: 0.56667rem;
}

.demo-table-expand label{
  width: 1.5rem;
}
.el-form-item__label{
  font-size: 0.26rem;
}
.el-form-item__content{
  font-size: 0.26rem;
}
.demo-table-expand{
  border-bottom: 0.01333rem solid #EBEEF5;
}
.demo-table-expand:last-child{
  border-bottom: 0rem;
}
.van-field__left-icon .van-icon, .van-field__right-icon .van-icon{
  font-size: 0.52667rem;
}
.van-search__content{
  padding-left: 0rem;
}
.van-search{
  padding: 0.1rem 0.32rem;
}
.van-button--normal{
  font-size: 0.45rem;
}
.el-table--mini, .el-table--small, .el-table__expand-icon{
  font-size: 0.36rem;
}
.el-table__expand-icon>.el-icon{
  left: 18%;
}
</style>